<template>
  <div>
    <canvas
      id="canvas"
      width="300"
      height="200"
      style="border: 1px solid #ddd"
    />
    <img id="my-image" src="../../assets/img/4252563496253317120.jpg">
    <el-upload
      class="upload-demo"
      :data="datas"
      action="http://weixing.bjgs.top/api/users/upFile.html"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-success="handSuccess"
      :before-remove="beforeRemove"
      multiple
      :limit="3"
      :on-exceed="handleExceed"
      :file-list="fileList"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">
        只能上传jpg/png文件,且不超过500kb
      </div>
    </el-upload>
    <div class="div">
      <div v-for="(item, index) in imgList" :key="index">
        <img :id="item.id" ref="mm" :src="item.url" width="100" height="100">
        <el-button
          size="small"
          type="primary"
          @click="add(item.id)"
        >添加图片</el-button>
      </div>
    </div>
    <img id="del" src="../../assets/img/default-store.png">
  </div>
</template>
<script>
const addIcon = require('../../assets/img/add_icon.png') // 新增按钮
const delIcon = require('../../assets/img/delete_icon.png') // 新增按钮
import fabric from 'fabric'
import 'fabric-customise-controls'
export default {
  data() {
    return {
      datas: { type: 3, token: 'b8fe88a1b3f6e381230d23a6102b0851' },
      fileList: [],
      canvas: '',
      imgList: []
    }
  },
  mounted() {
    const _this = this
    // 设置背景图片
    this.canvas = new fabric.fabric.Canvas('canvas')
    this.canvas.setWidth(1000)
    this.canvas.setHeight(600)
    const imgs = require('../../assets/img/order_routing.png')
    fabric.fabric.Image.fromURL(imgs, img => {
      img.set({
        // 通过scale来设置图片大小,这里设置和画布一样大
        scaleX: this.canvas.width / img.width,
        scaleY: this.canvas.height / img.height
      })
      // 设置背景
      this.canvas.setBackgroundImage(
        img,
        this.canvas.renderAll.bind(this.canvas)
      )
      this.canvas.renderAll()
    })
    // 添加图层
    const imgElement = document.getElementById('my-image')
    const imgInstance = new fabric.fabric.Image(imgElement, {
      left: 100, // 图片相对画布的左侧距离
      top: 100, // 图片相对画布的顶部距离
      angle: 30, // 图片旋转角度
      opacity: 0.85, // 图片透明度
      // 这里可以通过scaleX和scaleY来设置图片绘制后的大小,这里为原来大小的一半
      scaleX: 0.5,
      scaleY: 0.5
    })
    // 添加对象后, 如下图
    this.canvas.add(imgInstance)

    // 监听画布的图层编辑事件
    this.canvas.on('object:modified', e => {
      console.log(e.target)
      // e.target为当前编辑的Object
      // ...旋转,缩放,移动等编辑图层的操作都监听到
      // 所以如果有撤销/恢复的场景,这里可以保存编辑状态
    })
    this.canvas.on('object:selected', e => {
      console.log(e.target)
      // e.target为当前编辑的Object
      // ...旋转,缩放,移动等编辑图层的操作都监听到
      // 所以如果有撤销/恢复的场景,这里可以保存编辑状态
    })
    // 更改角落图标
    fabric.fabric.Object.prototype.customiseCornerIcons(
      {
        settings: {
          cornerSize: 35,
          cornerShape: 'rect',
          cornerPadding: 10
        },
        tl: {
          icon: addIcon,
          cursor: 'pointer'
        },
        tr: {
          icon: delIcon,
          cursor: 'pointer'
        }
      },
      function() {
        _this.canvas.renderAll()
      }
    )
    fabric.fabric.Canvas.prototype._getActionFromCorner = function(
      target,
      corner,
      e
    ) {
      if (!corner) {
        return 'drag'
      }
      switch (corner) {
        case 'mtr':
          return 'rotate'
        case 'ml':
          break
        case 'mr':
          return e[this.altActionKey] ? 'skewY' : 'scaleX'
        case 'mt':
        case 'mb':
          return e[this.altActionKey] ? 'skewX' : 'scaleY'
        case 'tr': // 增加左上删除动作
          _this.canvas.remove(target)
          return 'deleted'
        default:
          return 'scale'
      }
    }
  },
  methods: {
    handlePreview(file) {
      console.log(file)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件,本次选择了 ${
          files.length
        } 个文件,共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 文件上传后向html添加Img
    handSuccess(response, file, fileList) {
      const param = {
        id: fileList.length,
        url: response.data
      }
      this.imgList.push(param)
    },
    add(id) {
      const imgElement = document.getElementById(id)
      const imgInstance = new fabric.fabric.Image(imgElement, {
        left: 200, // 图片相对画布的左侧距离
        top: 100, // 图片相对画布的顶部距离
        angle: 30, // 图片旋转角度
        opacity: 0.85, // 图片透明度
        // 这里可以通过scaleX和scaleY来设置图片绘制后的大小,这里为原来大小的一半
        scaleX: 0.5,
        scaleY: 0.5
      })
      // 添加对象后, 如下图
      this.canvas.add(imgInstance)
    }
  }
}
</script>
<style scoped>
.c {
  border: 1px solid #009688;
  max-width: 1360px;
}
</style>
